import { Controller } from '@hotwired/stimulus'
import axios from 'axios' // 使用するHTTPクライアントライブラリ（Axiosなど）を追加

// Connects to data-controller="google-map"
export default class extends Controller {
  static targets = ['map']
  static values = { apiKey: String, address: String }

  connect() {
    // Load the Google Maps script asynchronously using the API key
    if (typeof google !== 'undefined') {
      this.initMap()
    } else {
      const script = document.createElement('script')
      script.src = `https://maps.googleapis.com/maps/api/js?key=${this.apiKeyValue}&callback=initMap`
      script.async = true
      script.defer = true
      window.initMap = this.initMap.bind(this)
      document.head.appendChild(script)
    }
  }

  async initMap() {
    // Use Geocoding API to get latitude and longitude from the address
    const geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(this.addressValue)}&key=${this.apiKeyValue}`

    try {
      // HTTPリクエストで地名から緯度・経度を取得
      const response = await axios.get(geocodeUrl)
      const location = response.data.results[0].geometry.location
      const circleCenter = { lat: location.lat, lng: location.lng }

      // Mapの作成
      const map = new google.maps.Map(this.mapTarget, {
        zoom: 14,
        center: circleCenter // Geocoding APIで取得した座標を使用
      })

      // 円の作成
      const circleRadius = 1000 // 円の半径（メートル単位）
      const circle = new google.maps.Circle({
        strokeColor: '#FF0000',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#FF0000',
        fillOpacity: 0.35,
        map: map,
        center: circleCenter,
        radius: circleRadius // 半径をメートルで指定
      })

      // 円をマップに表示
      circle.setMap(map)
    } catch (error) {
      console.error('Geocoding API request failed:', error)
    }
  }
}
